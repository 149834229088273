var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(" 3. Факторы, представляющие опасность для людей при пожаре ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center justify-center",attrs:{"cols":"2"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"absolute":"","lazy-src":"img/material/explosion.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/explosion.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{ref:"flash",staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","width":"250","elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.viewTab2()}}},[_c('v-img',{attrs:{"lazy-src":"img/material/flash.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/flash.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab3},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/explosion-2.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/explosion-2.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab4},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/fire-extinguisher.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/fire-extinguisher.svg"}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{staticClass:"d-flex align-center justify-center pa-2 white mb-10",attrs:{"flat":"","height":"200px"}},[_c('v-card-text',{staticClass:"title d-flex text-center justify-center font-weight-bold",model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1),_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"100%","relative":""}},[_c('v-img',{attrs:{"lazy-src":"img/material/fire-c.gif","max-height":"100%","max-width":"70%","contain":"","absolute":"","left":"","src":"img/material/man-burns.svg"}})],1)],1),_c('v-col',{staticClass:"d-flex flex-column align-center justify-center",attrs:{"cols":"2"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab5},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/fire.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/fire.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab6},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/thermometer.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/thermometer.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab7},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/smoke.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/smoke.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab8},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/co2.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/co2.svg"}})],1)]}}])})],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12"}},[_c('Hint')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }